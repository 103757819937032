.request-page-banner {
    background: url("../img/banners/request-call.jpg");
    background-position: top;
    height: 300px;
}


.request-button button {
    background-color: #faa21a !important;
    border: none;
    border-radius: 50px;
    color: #fff;
    padding: 10px 15px !important;
}

.form-card {
    background-color: #8126a30d;
}

.para {
    font-size: 15px;
}

.ant-input-outlined {
    background-color: transparent;
    border-radius: 5px;
    height: 35px;
}

.request-card p {
    overflow: visible;
    text-overflow: inherit;
    display: block;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;
    margin: 0;
}