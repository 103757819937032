/* @media (min-width:500px) { */
.fix-size {
    max-width: 500px !important;
    border: 1px solid #8080801c;
}

/* } */

.qrbg-style img {
    object-fit: cover;
}

.Clientimg {
    bottom: 0;
    width: 100%;
    text-align: center;
    transform: translateY(365px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Clientimg .client-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.contact-info-box {
    display: flex;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E3E3E3;
}

.contact-info-box a,
.contact-info-box a:hover {
    display: block;
    color: #000 !important;
}

.contact-info-box p {
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 400;
}

.contact-info-box .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    background: var(--theme-color-main);
    font-size: 1.8rem;
    margin-right: 1rem;
}

.contact-info-box .icon:first-child svg {
    width: 60px;
}

/* new */
.qr-font {
    font-size: 15px;
}

.social-link-qr {
    list-style-type: none;
}

.social-link-qr li a .qricon,
.mail-icon {
    color: var(--white-color) !important;
    background: var(--theme-color-main) !important;
    border-radius: 50px !important;
}

.mail-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.mail-icon svg {
    font-size: 35px;
}

.qricon {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-color);
    background: var(--theme-color-main);
    font-size: 1.6rem !important;
    margin-right: 0.8rem !important;
}

.qr-btn,
.qr-btn:hover {
    color: var(--text-white) !important;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 700;
}

.qr-btn div a {
    font-size: 15px !important;
}

.contact-details {
    margin-top: 340px;
}

.qrbg-style {
    height: 200px;
}

@media (max-width:430px) {
    .qr-mail {
        word-break: break-all;
    }
}

.Clientimg .client-img {
    /* width: 152px;
    height: 172px; */
    width: 140px;
    height: 140px;
    border: 1px solid #e3e3e3;
}

.bless-logo img {
    width: 180px !important;
    height: 200px !important;
}