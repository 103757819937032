@import url("https://fonts.googleapis.com/css2?family=Belleza&display=swap");

:root {
  /* --theme-color-light: #b4a284; */
  /* --theme-color-light: #304b3f; */
  /* --theme-color-light: #89aaa4; */
  /* --theme-color-main: #410f58; */
  --theme-color-main: #8126a3;
  /* --theme-color-dark: #462d04; */
  /* --theme-color-button: #f5c565; */
  --theme-color-button: #faa21a;
  --white-color: #fff;
  --black-color: #000;
}

.belleza-regular {
}

.main-heading {
  font-family: "Belleza", sans-serif;
}

body {
  margin: 0;
}

a {
  cursor: pointer;
}

.main-heading h2 {
  font-size: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Belleza", sans-serif;
}

.popup {
  height: 500px !important;
}

.add-modal {
  position: relative;
}

.modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none !important;
  background: transparent;
  color: #fff !important;
}

.add-modal .btn-close {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
  opacity: 1 !important;
}

@media (max-width: 568px) {
  .social-icon-bullet {
    align-items: flex-start !important;
  }

  .add-btn-mobile {
    font-size: 0.8rem;
  }
}

.popup-btn,
.add-modal .btn-primary {
  position: absolute;
  top: 77%;
  left: 50%;
  transform: translate(-50%, -77%);
  padding: 6px 20px !important;
  text-align: center !important;
  color: #fff !important;
  display: inline-block !important;
  border: 3px solid var(--theme-color-main) !important;
  --bs-btn-bg: linear-gradient(
    30deg,
    var(--theme-color-main) 50%,
    transparent 50%
  ) !important;
  background-image: -webkit-linear-gradient(
    30deg,
    var(--theme-color-main) 50%,
    transparent 50%
  ) !important;
  background-image: linear-gradient(
    30deg,
    var(--theme-color-main) 50%,
    transparent 50%
  ) !important;
  background-size: 600px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  -webkit-transition: background 300ms ease-in-out !important;
  transition: background 300ms ease-in-out !important;
  border-radius: 50px !important;
}

.popup-btn:hover,
.add-modal .btn-primary:hover {
  background-color: transparent !important;
  background-position: 150% !important;
  color: var(--theme-color-main) !important;
}

.service-price-offer-cancel-value {
  text-decoration: line-through !important;
}

.service-price-original-value {
  justify-content: space-between !important;
}

.service-price-offer {
  min-height: 50px;
  max-height: 30px;
  height: 10px;
  padding-bottom: 0;
}

/* ** Service page banner text ** */

.waviy {
  position: relative;
  -webkit-box-reflect: below -20px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
  font-size: 18px;
}

.waviy span {
  /* font-family: 'Alfa Slab One', cursive; */
  position: relative;
  display: inline-block;
  color: #fff;
  /* text-transform: uppercase; */
  animation: waviy 1s infinite;
  animation-delay: calc(0.1s * var(--i));
  background: var(--theme-color-main);
  border-radius: 50px;
  padding: 5px 15px;
}

.waviy b {
  color: red;
  text-align: center;
}

@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-5px);
  }
}

.service-whatsapp-icon {
  font-size: 35px;
  /* color: white; */
  border-radius: 20px;
}

.servicew-whatsapp-icon-poiunter {
  cursor: pointer !important;
}

.modal-header {
  z-index: 9 !important;
}

.spacing {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.astrology-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  /* padding: 20px; */
}

.vastu-page-section {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.handwritting-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.tarot-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.palmistry-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.numerlogy-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.reiki-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.corporate-page-service {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
}

.corporate-page-service img {
  border-radius: 10px;
}

.service-card-img {
  border-radius: 10px;
}

.benefits {
  border-radius: 10px;
}

.check-icon {
  list-style: none;
}

.check-icon li {
  display: flex;
  margin-bottom: 15px;
}

.check-icon li h5 {
  margin-bottom: 5px;
}

.icon-check {
  border: 1px solid #000;
  border-radius: 50%;
  background: var(--theme-color-button);
  color: #fff;
  height: auto;
  width: 40px !important;
  aspect-ratio: 1/1;
  /* padding: 10px; */
  /* font-size: 2rem; */
  display: flex;
  align-self: start;
  justify-content: center;
}

.background-card {
  border: 1px solid #cebeb1;
  border-radius: 10px;
  height: 100% !important;
  /* padding: 15px; */
  overflow: hidden;
  display: flex;
}

.background-card-right {
  flex-direction: row-reverse;
}

.background-card .number-about {
  width: 25%;
}

.background-card .corporate-content {
  width: 75%;
}

.background-card .corporate-content {
  padding: 15px;
}

/*
.corporate-image img {
  height: 300px;
  border-radius: 10px;
}

.corporate-content p {
  margin: 0;
} */

/* ************* Coporate ********** */
.number-about {
  width: 150px;
  background: var(--theme-color-main);
  color: #fff;
  box-shadow: 5px 0px 10px #e3e3e3;
}

.number h2 {
  font-size: 6rem;
}

.blogs-card {
  padding: 0 !important;
}

.blog-page-content {
  padding: 15px !important;
}

.blog-page-image img {
  border-radius: 10px 10px 0 0;
}

.blog-page-content p {
  line-height: normal !important;
}

.ariesblog-image img {
  border-radius: 10px;
}

.taurus-image img {
  border-radius: 10px;
}

.gemini-image img {
  border-radius: 10px;
}

.cancer-image img {
  border-radius: 10px;
}

.leo-image img {
  border-radius: 10px;
}

.virgo-image img {
  border-radius: 10px;
}

.icon-check svg {
  display: flex;
  align-self: center;
}

.right-card {
  display: flex;
  flex-direction: row-reverse;
}

.blank-section {
  min-height: 90vh;
}

.not-found-page .read-more,
.write-review-drawer .theme-btn,
.blank-section .theme-btn {
  background: var(--theme-color-main);
  padding: 10px 15px;
  border-radius: 50px;
  color: #fff !important;
}

.customer-page.page-title-area {
  background: url("./img/home-banner.webp");
}

@media (max-width: 992px) {
  .video-box {
    overflow-x: auto;
  }

  .video-box .video-card {
    min-width: 30%;
    width: 30%;
    margin-right: 1rem;
  }

  .video-box .video-card:last-child {
    margin-right: 0;
  }
}

@media (max-width: 500px) {
  .image-book-card img {
    height: auto !important;
  }

  .video-box {
    overflow-x: auto;
  }

  .video-box .video-card {
    min-width: 60%;
    width: 60%;
    margin-right: 1rem;
  }

  .video-box .video-card:last-child {
    margin-right: 0;
  }
}

@media (max-width: 568px) {
  .spacing {
    padding: 0 !important;
  }

  .mobile-spacing {
    padding-left: calc(1.5rem * 0.5) !important;
    padding-right: calc(1.5rem * 0.5) !important;
  }

  .content-banner {
    background: #ffffffe4;
    /* margin: 2rem; */
    padding: 15px;
    top: 0px !important;
    height: 95%;
    width: 95%;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .book-card .content-book {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 15px;
  }

  /* 
  .book-image {
    border-radius: 10px 10px 0 0 !important;
  } */

  .image-book-card img {
    height: auto !important;
    border-radius: 0 !important;
  }

  .payments-icons {
    display: flex;
    gap: 0.7rem;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
  }

  .image-book-card img {
    border-radius: 10px !important;
  }

  .main-heading h2 {
    font-size: 25px;
  }

  .book-now {
    /* display: flex; */
  }

  .service-price-offer {
    width: 40px;
  }

  .homebanner img {
    height: auto !important;
  }

  .page-title-area {
    height: 150px !important;
  }

  .background-card,
  .background-card.flex-row-reverse {
    flex-direction: column !important;
  }

  .background-card .number-about {
    width: 100%;
    padding: 1rem;
  }

  .background-card .corporate-content {
    width: 100%;
  }
}

.background-card .corporate-content .corporate-image {
  display: none;
}

/* Contact page css */
.contact-banner {
  background: url(./img/banners/contact\ us.jpg) !important;
}

.offcanvas.offcanvas-end {
  z-index: 99999 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.swiper-slide {
  height: auto !important;
}

.services-form .form-select:focus {
  box-shadow: none !important;
  border-color: var(--theme-color-main);
}

.contact-form input:focus,
.contact-form input:hover,
.ant-picker-outlined:focus,
.ant-picker-outlined:hover,
.contact-form textarea:hover,
.contact-form textarea:focus {
  border-color: var(--theme-color-main);
  box-shadow: none !important;
}

#NotiflixLoadingWrap,
#NotiflixNotifyWrap {
  z-index: 99999 !important;
}

.react-international-phone-input-container .react-international-phone-input {
  background-color: transparent !important;
}
.modal {
  z-index: 99999 !important;
}
@media (max-width: 568px) {
  .guest-button .theme-btn.w-100.h-100 {
    height: auto !important;
  }
}
